/* Colours from css theme */
$primary : var(--primary-color);
$secondary : var(--secondary-color);
$primary-background: var(--primary-background-color);

/* colours */
$gray: #ccc;
$red: #FF3824;
$green: #45D288;
$orange: #FFA400;

/* brighter hues for highlighted items */
$bright-navy: #122272;

$text-color: #333645;

/* type */
$body-font-family: "Lato",
"Helvetica Neue",
"Helvetica",
Helvetica,
Arial,
sans-serif;

$font-size-xs: 0.75rem; // 12px
$font-size-s: 0.875rem; // 14px
$font-size-standard: 1rem; // 16px
$font-size-m: 1.125rem; // 18px
$font-size-l: 1.3125rem; // 21px
$font-size-xl: 1.5rem; // 24px


$padding-unit: 32px;
