@mixin button-text-style {
    font-family: $body-font-family;
    font-size: $font-size-standard;
    font-weight: bold;
    line-height: 1;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
}

@mixin button($color: $primary) {
    display: inline-block;
    width: auto;
    padding: 0.75em 1.5em;
    border: none;
    box-shadow: inset 0 -2px 0 0 rgba(black, 0.2);
    border-radius: 4px;
    background: $color;
    color: white;
    @include button-text-style;
    transition: all .25s cubic-bezier(0.250, 0.460, 0.450, 0.940);
    cursor: pointer;
    &:hover {
        filter: brightness(0.90);
    }
}

[class^='button'] {
    @include button;
}

.button {
    &--success {
        @include button($green);
    }
    &--warning {
        @include button($orange);
    }
    &--danger {
        @include button($red);
    }
}

