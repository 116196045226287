body {
  font-family: $body-font-family;
  color: $text-color;
}

a {
  color: $primary;
}

b, strong {
  font-weight: bold;
}

i, em {
  font-style: italic;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: bold;
}

h1 {
  font-size: $font-size-xl;
}

h2 {
  font-size: $font-size-l;
}

h3 {
  font-size: $font-size-m;
}

h4, h5, h6 {
  font-size: $font-size-standard;
}

p {
  max-width: 40em; /* maybe this is too much of a blunt instrument? rethink if problematic */
  line-height: 1.4;
  margin-bottom: 1em;
  &:last-of-type {
    margin-bottom: 0;
  }
}

@mixin text--error {
  color: $red;
}

@mixin text--warning {
  color: $orange;
}

.text--error {
  @include text--error;
}

.text--warning {
  @include text--warning;
}

.error {
  color: red;
  padding: 10px 0;
}

.pointer {
  cursor: pointer;
}

.success {
  color: green;
  padding: 10px 0;
}
