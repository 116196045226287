$input-inner-shadow: inset 0px 1px 3px 0px rgba(black, .2);

.auth__container {
    input[type="text"],
    input[type="time"],
    input[type="number"],
    input[type="email"],
    input[type="password"],
    textarea,
    input[type="date"] {
        width: 320px;
        padding: 0.5em;
        border: 1px solid $gray;
        box-shadow: $input-inner-shadow;
        border-radius: 4px;
        font-family: $body-font-family;
        font-size: $font-size-standard !important;
        color: $text-color;
        line-height: 1;

        &:focus {
            outline: 0;
            border: 1px solid $primary;
            box-shadow: $input-inner-shadow, 0 0 1px 1px rgba($text-color, 0.15);
        }

        &:disabled {
            background-color: $gray !important;
            opacity: 0.8;
        }

        &.form-error {
            border-color: $red;
        }
    }

    textarea {
        min-height: 100px;

        &.small {
            min-height: 50px;
        }
    }

    input[type="date"] {
        width: 200px;
    }

    input[type="time"] {
        width: 65px;
    }

    input[type="time"]::-webkit-clear-button {
        display: none;
    }

    input[type="time"]::-webkit-inner-spin-button {
        opacity: 1
    }

    input[type="time"]::-ms-clear {
        display: none;
    }

    form {
        margin: 0;
    }

    fieldset {
        border: none;
        padding: 0;
    }

    label {
        display: block;
        font-size: $font-size-s;
        font-weight: bold;
        margin-bottom: $padding-unit/4;

        &.inline {
            display: inline-block;
        }
    }

    form.inline {
        label {
            display: inline-block;
            margin-right: $padding-unit/4;
        }
    }

    input.small {
        font-size: $font-size-xs;
        height: 15px;
        width: 50px;
        margin: 0 $padding-unit/5;
    }

    button {
        &:disabled {
            background-color: #3f51b5 !important;
            cursor: default;
            opacity: 0.6;
        }
    }


}