.auth__container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $primary-background;
  -webkit-font-smoothing: antialiased;
}

.auth__header {
  text-align: center;
  margin-bottom: $padding-unit/1.5;
}

.auth__heading {
  font-size: $font-size-xl;
  margin-bottom: $padding-unit/4;

  img {
    height: 80px;
  }
}

.auth__content {
  width: 100%;
  max-width: 320px;

  .errorlist {
    margin-top: 1em;
    font-size: $font-size-s;
    line-height: 1.3;
  }
}

.errorlist {
  color: $red;
  padding: 0 0 $padding-unit / 2 $padding-unit / 2;
  font-size: 14px !important;
}

.auth__form {
  background: white;
  padding: $padding-unit;
  padding-top: calc(#{$padding-unit} - 1em);
  box-shadow: inset 0 3px 0 0 $primary;
  position: relative;

  * {
    box-sizing: border-box;
  }

  label {
    margin-top: 1.5em;
  }

  input {
    width: 100% !important;
  }

  button {
    margin-top: 1.25em;
    width: 100%;
    display: block;
  }
}

.iaso {

  .auth__container input[type="text"],
  .auth__container input[type="time"],
  .auth__container input[type="number"],
  .auth__container input[type="email"],
  .auth__container input[type="password"],
  .auth__container textarea,
  .auth__container input[type="date"] {
    box-shadow: none !important;
    height: 45px;
  }

}

[class^="auth__text"] {
  font-size: $font-size-standard;
  font-weight: bold;
  line-height: 1.3;
  margin-top: 1em;
}

.auth__text {
  &--error {
    @include text--error;
  }

  &--warning {
    @include text--warning;
  }
}


$right-icon-position : 40px;

#display-password {
  position: absolute;
  width: 30px;
  bottom: 132px;
  border: 1px solid $primary;
  right: 40px;
  background-color: white;
  border-radius: 100%;
  height: 30px;
  cursor: pointer;
  &:hover {
    border: 1px solid;
    filter: brightness(0.90);
  }

  .hidden {
    display: none;
  }

  svg {
    width: auto;
    height: 15px;
    margin-bottom: -2px;
    fill: $primary;
    &:hover {
      filter: brightness(0.90);
    }
  }
}

.auth__message {
  background: white;
  border-radius: 4px;
  padding: $padding-unit;
  box-shadow: inset 0 3px 0 0 $primary, 0 0 10px 10px rgba(black, 0.15);
}

.login-link {
  margin: 10px 0;
  text-align: right;

  a {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}

.reset-password-link {
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}

.language-picker {
  height: 30px;
  padding: 0 0 0 10px;
  margin-top: 10px;
  margin-left: auto;
  display: block;
  border-radius: 5px;
  background-color: white;
  border: 1px solid #ccc;
}
